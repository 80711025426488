@import "../../Globals/Colors/colors";

#contact {
 

  #contactHeading::after {
    content: " " ;
    display: block ;
    width: 0% ;
    height: 2px ;
    background-color: $fifth ;
    transition: width 0.3s ;
    margin: 0 auto ;
  }

  #contactHeading:hover::after {
    width: 40% ;
  }
  #contactHeading {
    text-align: center;
  }

  .contextText {
    padding-top: 0px;
  }

  .heading-container {
    padding-bottom: 0px;
  }

  .btn-div {
    margin-top: 15px;
  }

  .section-number{
    font-size: 1.5rem;
  }
}
