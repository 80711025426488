@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');
$primary-color: #0d0e1d;
// $third-color: #df0100;
$blue-tint: #68809a;

$first: #1d0d0e;
$second: #613944;
// $third: #00A5B2;
$third: #2a64ac;
$fourth: #a0a3b6;
$fifth: #68809a;
