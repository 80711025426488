@import "../../Globals/Colors/colors";
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.heading::after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;

  background: $third;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}


.heading {
  cursor: pointer;
}

#about {
  padding-bottom: 0px !important;
  padding-top: 0px !important;

  .btn-get-in-touch{
    margin-bottom:90px; 
  }

  @media screen and (max-width: 428px) {
    .btn-get-in-touch-col{
      text-align: center;
    }
    .text-and-image-container{
      display: flex;
      flex-direction: column-reverse;
    }
    // margin-top: 80%;
  }


  .section-number{
    font-size: 1.5rem;
  }
}

.container {
  .row {
    margin: 0px auto;
  }
  p {
    line-height: 1.3;
    letter-spacing: 1px;
    // word-spacing: 1px;
    margin: 5px auto;
  }

  .technologies {
    // display: grid;
    // grid-template-columns: repeat(2, minmax(140px, 200px));
    // overflow: hidden;
    // padding: 0px;
    // margin: 20px 0px 0px;
    .first-list, .second-list{
      list-style: none;
      display: inline-block;
    }
    .second-list{
      padding-left: 30px;
    }
    
    @media screen and (max-width: 428px) {
      .first-list, .second-list{
        display: block;
        text-align: center;
      }

      .second-list {
        padding-left: 0px;
        ul{
          margin-top: 0px;
        }
        
      }

      .first-list{
        ul{
          margin-bottom: 0px;

        }
      }
    }
    
    li::before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      content: "\f0da";
      color: $third;
      font-family: FontAwesome;
      margin-right: 10px;
    }
  }
  .card {
    background-color: inherit;
  }
  .about-card{
    box-shadow: none!important;
    -webkit-box-shadow:none!important;
  }
}
