@import "../../Globals/Colors/colors";






.font-bold {
  font-weight: bold;
}

button {
  background-color: red;
}

.responsibilities {
  padding: 0px;
  margin: 20px 0px 0px;
  list-style: none;
  font-family: "Roboto", sans-serif;

  li::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    content: "\f101";
    color: $third;
    font-family: FontAwesome;
    margin-right: 20px;
  }
}
.heading-container {
  padding-bottom: 0px;
}
#experience {
  #experienceheading::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background-color: $fifth;
    transition: width 0.3s;
    margin: 0 auto;
  }
  
  #experienceheading:hover::after {
    width: 55%;
  }
  #experienceheading {
    text-align: center;
  }
  
  padding-top: 0px !important;

  .section-number{
    font-size: 1.5rem;
  }

  .exp-col{
    margin: 30px 0px;
  }

  .responsibilities li{
    margin: 5px 0px;
  }

  .experience-tabs{
    background-color: inherit !important;

    .tab a, .tab a.active, .tab a.focus.active{
      color: #68809a ;
      background-color: inherit !important;
    }

    .tab a.active{
      color:white;
    }
    .tab a:hover {
      color:white;
    }

    .tab a{
      transition: 0.3s ease-in;
    }

    .indicator{
      background-color: #00A5B2;
    }

    
  }

  
}
