@import "../../Globals/Colors/colors";

nav {
  background-color: $third !important;
  font-family: "Source Code Pro";
}
code {
  color: $third;
}

nav ul a:hover {
  background-color: $primary-color !important;
  color: $third !important;
  transition: 1s ease !important;
}

nav ul a:focus {
  // background-color: $first !important;
  // color: $third !important;
  // transition: 1s ease !important;
}
.nav-wrapper .brand-logo img {
  height: 43px;
  vertical-align: text-bottom;
  margin-left : 15px;
  @media screen and (max-width: 423px) {
    margin-left: 0px;
  }
}
.btn-resume {
  box-shadow: none;
  color: white;
  background-color: $fifth;
}

.btn-resume:hover {
  box-shadow: none;
  color: white !important;
  background-color: $third !important;
}

.btn-resume:focus {
  background-color: $third !important;
  color: white !important;
  transition: 1s ease !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  color: $third;
  font-family: "Roboto", sans-serif;
  font-weight: 700 !important;
  @media screen and (max-width: 423px) {
   font-size: 38px;
  }
}

p,
button,
ul {
  color: $fourth;
  font-family: "Roboto", sans-serif;
}

ul,
li {
  color: $fourth;
}


.sidenav{
  background-color: $primary-color;
  padding: 30px 10px;

  a{
    
    color: #00a5b2 !important;

  }
  .btn-resume{
    color : #fff !important;
  }
}
/* 
df0100 red
a0a3b6 grey
68809a blue
*/
