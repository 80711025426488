@import "../../Globals/Colors/colors";

.container {
  padding: 90px 20px;
}

.blue-tint{
  color : $blue-tint;
}

.left-align {
  margin: 0px auto;
}

.about-info {
  margin: 30px 0px !important;
}
.greeting {
  margin-bottom: 20px;
  font-family: "Source Code Pro", monospace;
}
.name {
  font-weight: bold;
}
@media screen and (max-width: 428px) {
  h1,h4,H5,p{
    text-align: center !important;
  }
  .btn-get-in-touch-col{
    text-align: center !important;
  }
}

#landing{
  height: 100vh; //vh - viewport height
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;

  @media screen and (max-width: 425px) {
    height: 100%;
  }

  .name{
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
}